/*
 * @Description: common
 * @Author: zhangguoliang
 * @Date: 2021-08-12 17:56:40
 * @LastEditors: liang
 * @LastEditTime: 2024-09-04 09:29:18
 */

import { Tag } from '@/interface';
import { Module } from 'vuex';
import RootState, { CommonState } from './interface';

const common: Module<CommonState, RootState> = {
  state: {
    tags: [],
    dictList: [],
    inviteToken: localStorage.getItem('inviteToken') || '',
    currentLoginMobile: localStorage.getItem('currentLoginMobile') || '',
    AGENCY_CODE: localStorage.getItem('AGENCY_CODE') || '',
    wxcode: localStorage.getItem('wxCode') || '',
    completeService: localStorage.getItem('completeService') || 'false',
  },
  mutations: {
    SET_TAGS(state, tags: Tag[]) {
      state.tags = tags;
    },
    SET_DICTLIST(state, dictList: string[]) {
      state.dictList = dictList;
    },
    SET_INVITE_TOKEN(state, inviteToken: string) {
      console.log(inviteToken);
      state.inviteToken = inviteToken;
    },
    SET_CURRENT_LOGIN_MOBILE(state, currentLoginMobile: string) {
      state.currentLoginMobile = currentLoginMobile;
    },
    SET_AGENCY_CODE(state, AGENCY_CODE: string) {
      state.AGENCY_CODE = AGENCY_CODE;
    },
    SET_WXCODE(state, wxcode: string) {
      state.wxcode = wxcode;
    },
    SET_COMPLETE_SERVICE(state, completeService: string) {
      state.completeService = completeService;
    },
  },
  getters: {
    tags(state) {
      return state.tags;
    },
    dictList(state) {
      return state.dictList;
    },
    inviteToken(state) {
      return state.inviteToken;
    },
    currentLoginMobile(state) {
      return state.currentLoginMobile;
    },
    AGENCY_CODE(state) {
      return state.AGENCY_CODE;
    },
    wxcode(state) {
      return state.wxcode;
    },
    completeService(state) {
      return state.completeService;
    },
  },
};

export default common;
