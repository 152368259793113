/*
 * @Description: axios request
 * @Author: zhangguoliang
 * @Date: 2021-08-03 16:11:11
 * @LastEditors: liang
 * @LastEditTime: 2024-09-06 09:57:29
 */

import axios from 'axios';
import store from '@/store';
import router from '@/router';
import { Notify } from 'vant';

import { isPlainObject } from 'lodash';
import { whiteList } from './whiteList';
const request = axios.create({
  baseURL: '/api',
  timeout: 10000,
});
request.interceptors.request.use(
  (config) => {
    const { url = '', headers } = config;
    let submitToken = window.localStorage.getItem('submitToken');
    const isSubmitToken = window.sessionStorage.getItem('isSubmitToken');

    let PRODUCT_CODE = 'SUPER_ADMIN';
    let TEMPLATE_CODE = '';
    if (isSubmitToken === 'NoExit') {
      submitToken = '';
    }
    if (isSubmitToken === 'NoExit' || isSubmitToken === 'exit') {
      PRODUCT_CODE = 'AGENCY_ADMIN';
      TEMPLATE_CODE = 'AGENCY_ADMIN';
    }
    if (headers.PRODUCT_CODE) {
      PRODUCT_CODE = headers.PRODUCT_CODE;
    }
    const newHeaders = {
      ...headers,
      AGENCY_CODE: 'EXPERIENCE_AGENCY',
      PRODUCT_CODE: PRODUCT_CODE,
      TEMPLATE_CODE: TEMPLATE_CODE,
    };

    if (url.indexOf('/smsCode') === -1) {
      newHeaders.submitToken = submitToken;
    }
    if (whiteList.findIndex((item) => url.indexOf(item) !== -1) !== -1) {
      newHeaders.Application_name = 'PORTAL';
      newHeaders.PRODUCT_CODE = 'AGENCY_ADMIN';
      newHeaders.Authorization = 'bearer ' + store.getters.inviteToken;
      if ((store.getters.AGENCY_CODE || '') !== '') {
        newHeaders.AGENCY_CODE = store.getters.AGENCY_CODE;
      }
    }

    window.sessionStorage.removeItem('isSubmitToken');
    return {
      ...config,
      url: `${url}`,
      headers: newHeaders,
    };
  },
  (err) => Promise.reject(err)
);

request.interceptors.response.use(
  (res) => {
    const { data, status } = res;
    console.log(res);
    const mess =
      data.message && data.status !== 500 ? data.message : '网络请求错误';
    if (
      (status >= 400 || data.status >= 400) &&
      (res?.config?.url ?? '').indexOf('/auth-resource/1/phone/') === -1
    ) {
      if (
        status === 401 ||
        status === 403 ||
        data.status === 401 ||
        data.status === 403
      ) {
        if (whiteList.findIndex(
          (item) => (res?.config?.url ?? '').indexOf(item) !== -1
        ) === -1) {
          if ((res?.config?.url ?? '').indexOf('/portal-resources/1') === -1) {
            store.dispatch('logout');
          }

        }
        else {
          store.commit("SET_INVITE_TOKEN", '');
          store.commit("SET_CURRENT_LOGIN_MOBILE", '');
          localStorage.removeItem('inviteToken')
          localStorage.removeItem('currentLoginMobile')
          //如果是公测接口，跳转公测登录页
          router.replace({
            path: `/openBetaInvite`,
          });
        }
      }
      console.log(res);
      if (
        whiteList.findIndex(
          (item) => (res?.config?.url ?? '').indexOf(item) !== -1
        ) === -1 ||
        (whiteList.findIndex(
          (item) => (res?.config?.url ?? '').indexOf(item) !== -1
        ) !== -1 &&
          mess.indexOf('access token') === -1)
      ) {
        Notify(mess);
      }
      return Promise.reject(res);
    }
    if ((res?.config?.url ?? '').indexOf('/portal-resources/1/portalAccount/withdrawal') !== -1) {
      return data;
    }
    else {
      return isPlainObject(data) && 'result' in data ? data.result : data;
    }

  },
  (err) => Promise.reject(err)
);

export default request.request;
