<!--
 * @Author: zhangguoliang
 * @Date: 2021-09-22 10:26:26
 * @LastEditors: liang
 * @LastEditTime: 2024-09-11 15:16:25
-->
<template>
  <a-config-provider :locale="$zhCN">
    <router-view></router-view>
  </a-config-provider>
</template>

<script>
import Vue from "vue";
import request from "@/utils/request";
import VConsole from "vconsole";
import { portalForward } from "@/api/invite";

import wx from "weixin-js-sdk"; //微信sdk依赖
export default Vue.extend({
  data() {
    return {
      match: undefined,
      baseUrl: "",
      share: [
        {
          path: "/home",
          title: "云算房—智能造价云平台",
          desc: "海量大数据,组价更智能,点击立即试用",
          link: "/home",
        },
        {
          path: "/appointment",
          title: "云算房一智能造价云平台",
          desc: "云算房智能造价云平台产品免费演示讲解,点击立即预约",
          link: "/appointment",
        },
        {
          path: "/coupons",
          title: "云算房一智能造价云平台",
          desc: "新用户扫码领劵,首单1元,解锁造价新体验",
          link: "/coupons",
        },
        {
          path: "/biddingPlan",
          title: "云算房一智能造价云平台",
          desc: "标书代做",
          link: "/biddingPlan",
        },
        {
          path: "/record",
          title: "云算房一智能造价云平台",
          desc: "活动邀请",
          link: `/invite`,
        },
        {
          path: "/openBetaShare",
          title: "云算房一智能造价云平台",
          desc: "活动邀请",
          link: `/openBetaShare`,
        },
        {
          path: "/openBetaShareEwm",
          title: "云算房一智能造价云平台",
          desc: "活动邀请",
          link: `/openBetaShareEwm`,
        },
      ],
    };
  },
  watch: {
    "$route.path": {
      handler(val, from) {
        console.log(777777, from, 88888888, val);
        // if (val === "/openBetaShareEwm") {
        //   if (from == "/" && !sessionStorage.getItem("isOnline")) {
        //     //外界连接
        //     this.$router.replace({
        //       path: `/openBetaShare?inviterMobile=${this.$route.query?.inviterMobile}`,
        //     });
        //   }
        // }
        this.match = this.share.find((item) => val.includes(item.path));
        if (this.match) {
          if (this.match.link === this.baseUrl + "/invite") {
            this.getSignature(this.baseUrl + this.$route.fullPath);
          } else {
            this.getSignature(this.match.link);
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    let baseUrl = process.env.VUE_APP_INVITE_URL;
    this.baseUrl = baseUrl;
    let newShare = JSON.parse(JSON.stringify(this.share));
    newShare = newShare.map((item) => {
      return { ...item, link: baseUrl + item.link };
    });
    this.share = newShare;
  },
  mounted() {
    // new VConsole();
    console.log(window.navigator.userAgent.toLowerCase());

    window.onload = function () {
      document.addEventListener("touchstart", function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      });
      document.addEventListener("gesturestart", function (event) {
        event.preventDefault();
      });
    };
  },
  methods: {
    getSignature(url) {
      console.log("分享", url);
      request({
        url: `${this.baseUrl}/weChat/getSignature?url=` + url,
        method: "get",
      })
        .then((res) => {
          const { timestamp, noncestr, signature } = res;
          console.log("777777777", res, noncestr, timestamp);
          wx.config({
            debug: false,
            appId: "wx17081ce61493aba9",
            timestamp: +timestamp,
            nonceStr: noncestr,
            signature,
            jsApiList: [
              "updateAppMessageShareData",
              "updateTimelineShareData",
              "onMenuShareAppMessage",
              "onMenuShareTimeline",
            ],
          });
          wx.ready(() => {
            let match = this.share.find((item) => this.$route.path.includes(item.path));
            let { title = "", desc = "", link = "", path = "" } = this.match || {};
            if (path === "/record") {
              link = `${this.baseUrl}/invite?inviterMobile=${this.$store.getters.currentLoginMobile}&invitationMethod=1`;
            }
            if (link !== "") {
              const imgUrl =
                "https://hzjt-ui-publiclib.oss-cn-beijing.aliyuncs.com/YYFUPT/log1o.png";
              wx.updateAppMessageShareData({
                title,
                desc,
                link,
                imgUrl,
                success(res) {
                  console.log("成功:" + JSON.stringify(res));
                  if (link == "/openBetaShareEwm") {
                    this.getPortalForward();
                  }
                },
                fail(res) {
                  console.log("失败:" + JSON.stringify(res));
                },
              });
              wx.updateTimelineShareData({
                title,
                desc,
                link,
                imgUrl,
                success(res) {
                  console.log("成功:" + JSON.stringify(res));
                  if (link == "/openBetaShareEwm") {
                    this.getPortalForward();
                  }
                },
                fail(res) {
                  console.log("失败:" + JSON.stringify(res));
                },
              });

              wx.error(function (res) {
                console.log("config1:" + JSON.stringify(res));
              });
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPortalForward() {
      portalForward().then(() => {
        //
      });
    },
  },
});
</script>

<style>
@font-face {
  font-family: Source Han Sans CN;
  src: url("./assets/fonts/SourceHanSansCN-Normal.ttf");
}

body {
  font-family: Source Han Sans CN;
}
</style>
